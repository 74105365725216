/* READ MODE */
#read-mode-header {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #ddd !important;
    box-shadow: 2px 2px 5px 0 rgba(153, 153, 153, 0.65) !important;
}

html.read-mode {
    background-image: none !important;
    background-color: #ffffff;
}

html.read-mode #sidebar-left {
    visibility: hidden;
}

html.read-mode header,
html.read-mode #sidebar-right,
html.read-mode .cookie-info,
html.read-mode .public-page .panel-heading .row,
html.read-mode .public-content .content-title,
html.read-mode #sidebar-left *,
html.read-mode .block-edit-button-group {
    display: none !important;
}

html.read-mode #wrapper {
    background-color: #ffffff;
    background-image: none !important;
}

html.read-mode #page-content-wrapper {
    margin-top: 60px;
}

html.read-mode #page-content-wrapper > .container-fluid {
    -webkit-box-shadow: 2px 2px 5px 0 rgba(153, 153, 153, 0.65);
    box-shadow: 2px 2px 5px 0 rgba(153, 153, 153, 0.65) !important;
}

html.read-mode .public-page .panel-heading {
    border-bottom: none;
}

html.read-mode #read-mode-header {
    display: block;
}

html.read-mode #read-mode-header .breadcrumbs * {
    background-color: #ddd !important;
    font-size: 12pt;
}

html.read-mode #read-mode-header .breadcrumbs {
    width: 90%;
    padding-right: 0;
    padding-left: 40px;
    float: left !important;
    background-color: #ddd !important;
}

html.read-mode #read-mode-header > .breadcrumbs > .breadcrumb {
    padding: 8px;
}

html.read-mode #read-mode-header > .breadcrumbs > .breadcrumb li {
    color: #515151;
}

html.read-mode #read-mode-header > .breadcrumbs > .breadcrumb li:last-child {
    color: #707173;
}

html.read-mode #read-mode-header > .breadcrumbs > .breadcrumb li i.fa-home {
    color: #515151;
}

html.read-mode #read-mode-header > .breadcrumbs > .breadcrumb > li + li:before {
    color: #515151;
}

html.read-mode #read-mode-header .switch-link {

}

html.read-mode #read-mode-header .switch-link :hover {
    background-color: #ffb5b5;
}

html.read-mode #read-mode-header .switch-link > a {
    position: fixed;
    top: 0px;
    right: 0px;
    padding: 9.5px;
    font-size: 14px;
    color: #444;
    background-color: #f9cece;

}

html.read-mode #read-mode-header .switch-link > a i {
    color: #444;
}

html.read-mode #read-mode-header .headline-icon {
    background-color: #ddd !important;
    width: 4%;
}

html.read-mode #read-mode-header .headline-icon * {
    background-color: #ddd !important;
}

html.read-mode #read-mode-header .headline-icon > i {
    font-size: 15pt;
    padding: 0.49em;
    width: 2em;
    position: absolute;
    left: 0;
    color: #444;
    top: 0;
}

@keyframes rec {
    0% {
        color: rgba(255, 0, 0, 1)
    }
    50% {
        color: rgba(255, 0, 0, 0.5)
    }
    100% {
        color: rgba(255, 0, 0, 1)
    }
}

@-webkit-keyframes rec {
    0% {
        color: rgba(255, 0, 0, 1)
    }
    50% {
        color: rgba(255, 0, 0, 0.5)
    }
    100% {
        color: rgba(255, 0, 0, 1)
    }
}

.rec {
    -moz-transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -moz-animation: blink normal 1.5s infinite ease-in-out;
    /* Firefox */
    -webkit-animation: rec normal 1.5s infinite ease-in-out;
    /* Webkit */
    -ms-animation: rec normal 1.5s infinite ease-in-out;
    /* IE */
    animation: rec normal 1.5s infinite ease-in-out;
    /* Opera */
}

.listjs-panel .sort {
    align-items: baseline;
    cursor: pointer;
    display:flex;
    justify-content: space-between;
    position: relative;
    padding-right: 15px;
    padding-left: 15px;
}

.listjs-panel .sort:after {
    color: #333;
    font-family: 'Font Awesome 5 Pro';
    font-size: 0.9em;
    font-weight: lighter;
    margin-left: 5px;
}

.listjs-panel .sort.asc:after {
    content: "\f0de";
}

.listjs-panel .sort.desc:after {
    content: "\f0dd";
}

